
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoginModal from '@/components/Login.vue'
import { AUTH_LOGOUT } from '@/store/actions/auth'

export default Vue.extend({
    name: 'Home',
    components: {
        LoginModal,
    },
    methods: {
        logout: function () {
            this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/'))
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'isAdmin']),
    }
})
